
import { PropType, defineComponent, ref, watch } from "vue";
import { Modal } from "bootstrap";
import moment from "moment";

export interface ILog {
  id: number,
  url: string | null,
  method: string | null,
  status_header: number | null,
  status: string | null,
  id_company: number | null,
  id_store: number | null,
  id_user:  number | null,
  email: string | null,
  storage_url: string | null,
  operation_type: string | null,
  header: JSON | null,
  payload: JSON | null,
  return: JSON | null,
  ps: string | null,
  created_at: string,
  updated_at: string,
  movement_id: string | null,
  companyCNPJ: string | null,
  companyName: string | null,
  storeCNPJ: string | null,
  storeName: string | null,
  userCPF: string | null,
  userName: string | null,
  label: string
  id_job: number | null
  competitorID: string | null
  competitorName: string
  competitorCPF: string
}

export default defineComponent({
  name: "ModalInfoLog",
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    log: {
      type: Object as PropType<ILog | null>,
      required: true
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const header = ref(`Log registrado em ${moment(props.log?.created_at).locale('pt-br').format('DD [de] MMMM [de] YYYY')}`)

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      header.value = `Log registrado em ${moment(props.log?.created_at).locale('pt-br').format('DD [de] MMMM [de] YYYY')}`
    }

    // Life Cycles
    watch(() => props.toggle, () => {
      openModal("ModalInfoLog")
    })

    return {
      header,
      moment
    }
  }
})
